<template>
    <el-dialog
            :title="title"
            :visible.sync="createDialogVisible"
            width="800px"
            :close-on-click-modal="false"
            :append-to-body="true"
            v-drag-dialog
            @close="close">
        <div class="pageContainer">
            <el-form label-position="right"
                     :model="formData"
                     :rules="productUsageRules"
                     ref="ProductForm"
                     label-width="100px"
                     element-loading-background="rgba(0, 0, 0, 0.8)">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="过往剧集名称" prop="name">
                    <el-input v-model="formData.name" placeholder="请输入商品名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="过往剧集封面" prop="cover">
                    <img :src="formData.cover" @click="showImg(formData.cover, '商品封面')" class="smallImg" alt="">
                    <file-upload-btn v-if="createDialogVisible" @uploadSuccess="handleUploadCover"></file-upload-btn>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-row type="flex" justify="center">
                <el-button type="primary" @click="trySubmit" :loading="addingLoading">确认提交</el-button>
            </el-row>
        </div>
    </el-dialog>
</template>

<script>
  import {mapActions} from 'vuex'
  import FileUploadBtn from 'modules/components/fileUploadBtn/index.vue'
  import {
    productUsageRules,
    resetProductUsage,
    execute
  } from '../option'

  export default {
    name: 'addOrEditProductUsage',
    components: { FileUploadBtn },
    data() {
      return {
        optType: 'add',
        createDialogVisible: false,
        callback: null,
        formData: resetProductUsage(),
        productUsageRules,
        addingLoading: false,
        supplierList: []
      }
    },
    computed: {
      title() {
        return this.optType === 'add' ? '新增商品过往剧集' : '编辑商品过往剧集'
      }
    },
    created() {
      this.tryGetSupplier('')
    },
    methods: {
      ...mapActions(['saveProductUsage', 'getProductUsageById', 'updateProductUsage', 'getSupplierList']),
      show(options) {
        this.optType = options.optType
        this.callback = options.callback
        if (this.optType === 'edit') {
          // this.getProductUsageById(options.detail.id)
          this.formData = options.detail
        } else {
          this.formData = resetProductUsage()
          this.formData.productId = options.detail.id
          this.formData.supplierId = options.detail.supplierId
        }
        this.createDialogVisible = true
        this.$nextTick(() => {
          this.$refs.ProductForm.clearValidate()
        })
      },
      close() {
        this.createDialogVisible = false
      },
      // 展示图片
      showImg(imgUrl, title) {
        execute('showImg', {
          imgUrl,
          title
        })
      },
      // 上传文件
      handleUploadCover(url) {
        this.formData.cover = url
      },
      tryGetProductById(id) {
        this.getProductUsageById(id).then(res => {
          this.formData = res || this.formData
        })
      },
      tryUpdateProductUsage() {
        this.addingLoading = true
        this.updateProductUsage(this.formData).then(() => {
          this.$message.success('操作成功')
          this.callback && this.callback()
          this.close()
        }).finally(() => {
          this.addingLoading = false
        })
      },
      trySubmit() {
        if (this.addingLoading) {
          return
        }
        this.$refs.ProductForm.validate((valid) => {
          if (valid) {
            const api = this.optType === 'add' ? this.trySaveProductUsage : this.tryUpdateProductUsage
            api()
          }
        })
      },
      trySaveProductUsage() {
        this.addingLoading = true
        this.saveProductUsage(this.formData).then(() => {
          this.$message.success('提交成功')
          this.resetProductUsage()
          this.callback && this.callback()
          this.close()
        }).finally(() => {
          this.addingLoading = false
        })
      },
      tryGetSupplier(keyword) {
        this.getSupplierList({
          page: 1,
          size: 30,
          name: keyword
        }).then(res => {
          const { recordList } = res
          this.supplierList = recordList
        })
      },
      resetProductUsage() {
        this.formData = resetProductUsage()
      }
    }
  }
</script>
<style lang="scss">
    .numberInput {
        width: 100%;

        .el-input {
            .el-input__inner {
                text-align: left;
            }
        }
    }
</style>
<style scoped lang="scss">
    @import "../../../scss/elementVar";

    .pageContainer {
        padding: 20px;
    }

    .tagWrap {
        margin-bottom: 20px;
    }

    .uploadBtn {
        padding: 30px 0;
        width: 150px;
        height: 100px;
        box-sizing: border-box;
        text-align: center;
        color: $--color-info;
        border: 2px dashed $--color-info;
        opacity: 0.7;
        border-radius: 4px;
        transition: all linear 100ms;
    }

    .uploadBtn:hover {
        opacity: 1;
    }

    .uploadBtnIcon {
        font-weight: bold;
        font-size: 30px;
    }

    .uploadBtnText {
        line-height: 10px;
        font-size: 14px;
    }

    .imgContainer {
        float: left;
        margin: 0 10px 10px 0;
    }

    .smallImg {
        width: 40px;
    }
    .widthCover{
        width: 100%;
    }
</style>
